<template>
  <el-container>
    <el-main>
      <el-row type="flex" justify="center" align="center">
        <el-col :md="12" :sm="24">
          <el-card shadow="always"
            ><img
              src="/images/logo.png"
              style="width: 250px; margin-top: 15px"
            />
            <el-divider></el-divider>
            <h5 class="text-muted">Insira suas credenciais para entrar</h5>

            <form>
              <el-input
                placeholder="Usuário"
                v-model="username"
                v-on:keyup.enter="signIn"
              >
                <template #prepend><i class="el-icon-user"></i></template>
              </el-input>

              <el-input
                placeholder="Senha"
                v-model="password"
                type="password"
                v-on:keyup.enter="signIn"
              >
                <template #prepend><i class="el-icon-key"></i></template>
              </el-input>

              <el-row justify="right">
                <el-switch v-model="remember" active-text="Lembrar minha senha">
                </el-switch>
              </el-row>
            </form>

            <div class="bottom">
              <el-button type="text" class="button" @click="redefinePassword"
                >Esqueci minha senha</el-button
              >
              <el-button type="text" class="button" @click="signIn"
                >Entrar</el-button
              >
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import { ElMessageBox } from "element-plus";
export default {
  name: "Login",
  data: () => ({
    username: "",
    password: "",
    remember: false,
  }),
  methods: {
    signIn() {
      this.$store
        .dispatch("authenticate", {
          username: this.username,
          password: this.password,
          remember: this.remember,
        })
        .then(() => this.$router.replace("/"))
        .catch(async (e) => this.notifyUser(await e));
    },
    async redefinePassword() {
      ElMessageBox.prompt("Insira o seu usuário", "Esqueci a minha senha", {
        confirmButtonText: "Redefinir",
        cancelButtonText: "Cancelar",
      })
        .then((v) => {
          this.updatePassword({
            username: v.value,
          })
            ?.then((r) => {
              if (r.status === 200) return r.json();
              else throw r.json();
            })
            ?.then(
              () =>
                this.$notify({
                  title: "A senha foi redefinida",
                  type: "success",
                  message: "O usuário receberá no email a nova senha",
                  position: "bottom-right",
                }),
              async (e) =>
                this.$notify({
                  title: "Não foi possível redefinir a senha.",
                  type: "error",
                  message: (await e).message,
                  position: "bottom-right",
                })
            );
        })
        .catch(() => {});
    },
    updatePassword(user) {
      if (user) {
        return fetch(`${this.$store.state.apiUrl}users/password/redefine`, {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
      }
    },
    notifyUser(e) {
      this.$notify({
        type: "error",
        title: "Não foi possível entrar",
        message: e.message,
        position: "bottom-right",
      });
    },
  },
};
</script>
<style scoped>
div >>> .el-card {
  margin: auto;
}
.text-muted {
  color: grey;
}
.el-row {
  margin-top: 20px !important;
}
.el-main {
  margin-left: 0px !important;
}
.el-input {
  margin-top: 20px !important;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>